import { onMount, onCleanup } from "solid-js";

function LoginSignUp(props) {
  const bodyClasses = "d-flex align-items-center py-4 bg-body-tertiary";

  onMount(() => {
    document.body.classList.add(...bodyClasses.split(" "));
    document.getElementById("root").classList.add("login-root");
  });

  onCleanup(() => {
    document.body.classList.remove(...bodyClasses.split(" "));
    document.getElementById("root").classList.remove("login-root");
  });

  return <>{props.children}</>;
}

export default LoginSignUp;
