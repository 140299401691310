import { createSignal, onMount } from "solid-js";
import authService from "../services/auth";
import { useNavigate } from "@solidjs/router";
import LoginSignUp from "../components/LoginSignUp";

function ResetPassword() {
  const [password, setPassword] = createSignal("");
  const [confirmPassword, setConfirmPassword] = createSignal("");
  const [error, setError] = createSignal("");
  const [token, setToken] = createSignal("");
  const navigate = useNavigate();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const isPasswordStrong = (password) => {
    // Minimum password length requirement
    if (password.length < 8) {
      return false;
    }

    // Additional strength requirements (e.g., at least one uppercase letter, one lowercase letter, and one digit)
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    return regex.test(password);
  };

  onMount(async () => {
    const token = getQueryParam("token");
    if (token) {
      setToken(token);
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password() !== confirmPassword()) {
      setError("Passwords do not match");
      return;
    }

    if (!isPasswordStrong(password())) {
      setError(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit"
      );
      return;
    }

    try {
      await authService.resetPassword(password(), token());
      navigate("/login"); // Redirect to login page
    } catch (err) {
      setError(`Failed to reset password: ${err}`);
      console.error(err);
    }
  };

  return (
    <LoginSignUp>
      <main class="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit} class="form">
          <div class="form-group">
            <label for="newPassword">New Password:</label>
            <input
              type="password"
              class="form-control"
              id="newPassword"
              value={password()}
              onInput={(e) => setPassword(e.target.value)}
            />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              value={confirmPassword()}
              onInput={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {error() && <p class="error">{error()}</p>}
          <button type="submit" class="btn btn-primary">
            Reset Password
          </button>
        </form>
      </main>
    </LoginSignUp>
  );
}

export default ResetPassword;
