import { createSignal, createContext, useContext } from "solid-js";

const AppContext = createContext();

export function AppContextProvider(props) {
  // eslint-disable-next-line solid/reactivity
  const [email, setEmail] = createSignal(props.email || "");
  const [globalMessage, setGlobalMessage] = createSignal("");

  return (
    <AppContext.Provider
      value={{ email, setEmail, globalMessage, setGlobalMessage }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
