import "./Login.css";
import { createStore } from "solid-js/store";
import { createEffect, createSignal } from "solid-js";
import LoginSignUp from "../components/LoginSignUp";
import authService from "../services/auth";
import { useNavigate } from "@solidjs/router";
import { useAppContext } from "../contexts/AppContext";

function SignUp() {
  const [form, setForm] = createStore({
    firstName: "",
    lastName: "",
    email: "",
    householdName: "",
    password: "",
    password2: "",
    error: "",
  });
  const [error, setError] = createSignal();
  const { setEmail } = useAppContext();
  const navigate = useNavigate();
  const handleSignUp = async (e) => {
    try {
      if (e) e.preventDefault();
      await authService.signUp(
        form.email,
        form.password,
        form.firstName,
        form.lastName,
        form.householdName
      );
      setEmail(form.email);
      navigate("/register/verify", { replace: true });
    } catch (err) {
      setError(`Failed to Signup${err.message ? `: ${err.message}` : ""}`);
    }
  };
  createEffect(() => {
    // TODO: Need to change this to cookies at some point
    if (localStorage.getItem("accessToken")) {
      navigate("/", { replace: true });
    }
  });

  return (
    <LoginSignUp>
      <main class="form-signup w-100 m-auto">
        <form method="post" class="form-signup" onSubmit={handleSignUp}>
          <h1>Jaime Cooks!</h1>
          <h1 class="h3 mb-3 fw-normal">Sign Up</h1>
          {error() && (
            <div class="alert alert-danger" role="alert">
              {error()}
            </div>
          )}
          {/* {% if error_messages %}
            {% for message in messages %}
            <div class="alert alert-danger" role="alert">{{ message }}</div>
            {% endfor %}
        {% endif %}
        {% if form.non_field_errors %}
        <div class="alert alert-danger" role="alert">
            {{ form.non_field_errors }}
        </div>
        {% endif %} */}
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="first_name"
              //  {% if form.first_name.errors %}is-invalid{% endif %}"
              placeholder="First Name"
              autocomplete="off"
              maxlength="100"
              value={form.firstName}
              onInput={(e) => setForm("firstName", e.target.value)}
            />
            <label for="first_name">First Name</label>
            {/*
            {% if form.first_name.errors %}
            <div class="invalid-feedback">
                {{ form.first_name.errors.as_text }}
            </div>
            {% endif %} */}
          </div>
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="last_name"
              //  {% if form.last_name.errors %}is-invalid{% endif %}"
              placeholder="First name"
              autocomplete="off"
              maxlength="100"
              value={form.lastName}
              onInput={(e) => setForm("lastName", e.target.value)}
            />
            <label for="last_name">Last Name</label>
            {/*
            {% if form.last_name.errors %}
            <div class="invalid-feedback">
                {{ form.last_name.errors.as_text }}
            </div>
            {% endif %} */}
          </div>
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="email"
              //  {% if form.email.errors %}is-invalid{% endif %}"
              placeholder="Your email"
              autocapitalize="none"
              maxlength="100"
              value={form.email}
              onInput={(e) => setForm("email", e.target.value)}
            />
            <label for="email">Email</label>
            {/*
            {% if form.email.errors %}
            <div class="invalid-feedback">
                {{ form.email.errors.as_text }}
            </div>
            {% endif %} */}
          </div>
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="household_name"
              //  {% if form.household_name.errors %}is-invalid{% endif %}"
              placeholder="Household name"
              autocapitalize="none"
              maxlength="100"
              value={form.householdName}
              onInput={(e) => setForm("householdName", e.target.value)}
            />
            <label for="household_name">Household Name</label>
            {/*
            {% if form.household_name.errors %}
            <div class="invalid-feedback">
                {{ form.household_name.errors.as_text }}
            </div>
            {% endif %} */}
          </div>
          <div class="form-floating">
            <input
              type="password"
              class="form-control"
              id="password1"
              //  {% if form.password1.errors %}is-invalid{% endif %}"
              placeholder="Password"
              autocapitalize="none"
              maxlength="100"
              value={form.password}
              onInput={(e) => setForm("password", e.target.value)}
            />
            <label for="password1">Password</label>
            {/*
            {% if form.password1.errors %}
            <div class="invalid-feedback">
                {{ form.password1.errors.as_text }}
            </div>
            {% endif %} */}
          </div>
          <div class="form-floating">
            <input
              type="password"
              id="password2"
              class="form-control"
              //  {% if form.password2.errors %}is-invalid{% endif %}"
              placeholder="Confirm password"
              onInput={(e) => setForm("password2", e.target.value)}
            />
            <label for="password2">Confirm password</label>
            {/*
            {% if form.password2.errors %}
            <div class="invalid-feedback">
                {{ form.password2.errors.as_text }}
            </div>
            {% endif %} */}
          </div>
          <button class="btn btn-primary w-100 py-2" type="submit">
            Sign Up
          </button>
        </form>
        <p class="mt-2">
          Already have an account? <a href="/login">Log in</a>.
        </p>
        <p class="mt-5 mb-3 text-body-secondary">&copy; 2023</p>
      </main>
    </LoginSignUp>
  );
}

export default SignUp;
