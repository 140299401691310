import { Show, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import authService from "../services/auth";
import { useAppContext } from "../contexts/AppContext";
import { useNavigate } from "@solidjs/router";

const VerifyEmail = () => {
  const animatedMessageStr = "Verifying your email, please wait";
  const navigate = useNavigate();
  const { email, setGlobalMessage } = useAppContext();
  const [tokenPresent, setTokenPresent] = createSignal(false);
  const [animatedMessage, setAnimatedMessage] =
    createSignal(animatedMessageStr);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  createEffect(() => {
    // TODO: Need to change this to cookies at some point
    if (localStorage.getItem("accessToken")) {
      navigate("/", { replace: true });
    }
  });

  let dotAnimation;

  onMount(async () => {
    const token = getQueryParam("token");
    if (token) {
      setTokenPresent(true);
      dotAnimation = setInterval(() => {
        setAnimatedMessage((prev) => {
          if (prev.endsWith("...")) return animatedMessageStr;
          return prev + ".";
        });
      }, 500);

      try {
        await authService.verifyToken(getQueryParam("token")); // if we don't throw an error, the token is valid
        setGlobalMessage("Your email has been verified! Please login.");
        navigate("/login"); // Redirect to login page
      } catch (err) {
        setGlobalMessage("Failed to verify your email");
        setAnimatedMessage(`Failed to verify your email: ${err}`);
        clearInterval(dotAnimation);
        console.error(err);
      }

      onCleanup(() => {
        clearInterval(dotAnimation);
      });
    }
  });

  return (
    <div class="login-signup-container">
      <h1 class="login-signup-title">Verify Email</h1>
      <Show
        when={tokenPresent()}
        fallback={
          <p class="login-signup-text">
            We have sent an email to {email} with a verification link. Please
            check your inbox and click on the link to verify your account.
          </p>
        }
      >
        <p class="login-signup-text">{animatedMessage()}</p>
      </Show>
    </div>
  );
};

export default VerifyEmail;
