import "./App.css";
import { Routes, Route } from "@solidjs/router";
import { lazy } from "solid-js";
import VerifyEmail from "./pages/VerifyEmail";
import Login from "./pages/Login";
import RouteGuard from "./RouteGuard";
import SignUp from "./pages/SignUp";
import { AppContextProvider } from "./contexts/AppContext";
import ResetPassword from "./pages/ResetPassword";

const Main = lazy(() => import("./pages/Main"));

function App() {
  return (
    <AppContextProvider>
      <Routes>
        <Route path="/login" component={Login} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/register">
          <Route path="/" component={SignUp} />
          <Route path="/verify" component={VerifyEmail} />
        </Route>
        <Route path="/" component={RouteGuard}>
          <Route path="/" element={Main} />
        </Route>
      </Routes>
    </AppContextProvider>
  );
}

export default App;
