import axios from 'axios';

export const API_URL_BASE = import.meta.env.VITE_API_URL_BASE;
export const COOKIE_SETTINGS = import.meta.env.VITE_COOKIE_SETTINGS;

const authService = {
  async login(email, password) {
    try {
      const response = await axios.post(`${API_URL_BASE}/auth/jwt/login`, new URLSearchParams({
        "username": email,
        password,
        "grant_type": "password",
      }));

      // Assuming the tokens are in the response body
      const { access_token, refresh } = response.data;

      // Store tokens in localStorage or handle them as needed
      // TODO: I need to move this to a cookie at some point
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh);

      // And also in a cookie for the websocket
      document.cookie = `jwt=${access_token};${COOKIE_SETTINGS}`;
 
      return response.data;
    } catch (error) {
      console.error('Login error:', error.response);
      throw new Error(error.response.data.detail);
    }
  },
  async signUp(email, password, firstName, lastName, householdName) {
    try {
      const response = await axios.post(`${API_URL_BASE}/auth/register`, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        household_name: householdName,
      });

      return response.data;
    } catch (error) {
      console.error('Sign up error:', error.response);
      throw new Error(error.response.data.detail);
    }
  },
  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  },
  async verifyToken(token) {
    try {
      const {data} = await axios.post(`${API_URL_BASE}/auth/verify`, {
        token: token,
      });
      return data;
    } catch (error) {
      console.error("Error verifying token:", error);
      throw new Error(error.response.data.detail);
    }
  },
  async getUser() {
    try {
      const headers = { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` };
      const {data} = await axios.get(`${API_URL_BASE}/users/me`, { headers });
      return data;
    } catch (error) {
      console.error("Error getting user:", error);
      throw new Error(error.response.data.detail);
    }
  },
  async resetPassword(password, token) {
    try {
      const {data} = await axios.post(`${API_URL_BASE}/auth/reset-password`, {
        password,
        token,
      });

      return data;
    } catch (error) {
      console.error('Reset password error:', error.response);
      throw new Error(error.response.data.detail);
    }
  },
  async requestResetPassword(email) {
    try {
      const {data} = await axios.post(`${API_URL_BASE}/auth/forgot-password`, {
        email,
      });

      return data;
    } catch (error) {
      console.error('Request reset password error:', error.response);
      throw new Error(error.response.data.detail);
    }
  }
};

export default authService;
