import { createSignal, createEffect, Show } from "solid-js";
import authService from "../services/auth";
import { useNavigate } from "@solidjs/router";
import LoginSignUp from "../components/LoginSignUp";
import { useAppContext } from "../contexts/AppContext";

function Login() {
  const [email, setEmail] = createSignal("");
  const [password, setPassword] = createSignal("");
  const [error, setError] = createSignal("");
  const { globalMessage } = useAppContext();

  const navigate = useNavigate();
  const handleLogin = async (e) => {
    try {
      if (e) e.preventDefault();
      await authService.login(email(), password());
      navigate("/", { replace: true });
    } catch (err) {
      setError(`Failed to login${err.message ? `: ${err.message}` : ""}`);
    }
  };
  createEffect(() => {
    // TODO: Need to change this to cookies at some point
    if (localStorage.getItem("accessToken")) {
      navigate("/", { replace: true });
    }
  });
  // If there is a global message, let's set it as the error
  createEffect(() => {
    if (globalMessage()) {
      setError(globalMessage());
    }
  });

  const handleForgotPassword = async (e) => {
    // prevent default event handling
    e.preventDefault();

    if (!email()) {
      setError("Please enter your email address.");
      return;
    }

    try {
      await authService.requestResetPassword(email());

      setError("A reset password link has been sent to your email address.");
    } catch (err) {
      setError(`Failed to send reset password link: ${err.message}`);
    }
  };

  return (
    <LoginSignUp>
      <main class="form-signin w-100 m-auto">
        <form method="post" class="form-signin" onSubmit={handleLogin}>
          <h1>Jaime Cooks!</h1>
          <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

          <Show when={error()}>
            <div class="alert alert-danger" role="alert">
              {error()}
            </div>
          </Show>

          <div class="form-floating">
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Your email"
              name="email"
              autofocus
              autocapitalize="none"
              autocomplete="off"
              maxLength="100" // Replace with actual max length
              value={email()}
              onInput={(e) => setEmail(e.target.value)}
            />
            <label for="email">email</label>
          </div>

          <div class="form-floating">
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Password"
              name="password"
              onInput={(e) => setPassword(e.target.value)}
              value={password()}
            />
            <label for="password">Password</label>
          </div>

          <button class="btn btn-primary w-100 py-2" type="submit">
            Sign in
          </button>

          <p class="mt-2">
            Don't have an account? <a href="/register">Sign up</a>
            <br />
            <a href="/forgot-password" onClick={handleForgotPassword}>
              Forgot your password?
            </a>
          </p>
          <p class="mt-5 mb-3 text-body-secondary">&copy; 2023</p>
        </form>
      </main>
    </LoginSignUp>
  );
}

export default Login;
