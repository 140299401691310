import { Outlet, useNavigate } from "@solidjs/router";
import { createEffect } from "solid-js";

export default function RouteGuard() {
  const navigate = useNavigate();
  // TODO: Need to change this to cookies at some point
  const token = localStorage.getItem("accessToken");

  createEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  });

  return (
    <div>
      <Outlet />
    </div>
  );
}
